import React from "react";
import {Carousel} from "react-bootstrap";

const Banner = ({banners}) => {
  return (
    <Carousel>
      {
        banners.map((x, index) => {
          return (
            <Carousel.Item key={index} interval={3000}>
              <img src={x.imageLink.file.url} className="d-block" alt={x.imageLink.title}/>
            </Carousel.Item>
          )
        })
      }
    </Carousel>
  )
}

export default Banner;
