import React, {memo} from "react";
import {Carousel} from "react-bootstrap";

export const Factory = memo(({factories}) => {
  return (
    <Carousel>
      {
        factories.map((x, index) => {
          return (
            <Carousel.Item key={index} interval={3000}>
              <img src={x.imageLink.file.url} className="d-block" alt={x.imageLink.title}/>
              <Carousel.Caption>
                <h5>{x.name}</h5>
                <div>{x.introduction.introduction}</div>
              </Carousel.Caption>
            </Carousel.Item>
          )
        })
        }
    </Carousel>
  )
})