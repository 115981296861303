import React from "react";
import cls from "classnames"

const link = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlGBYp4b2-b2xuKkx0i8Fzntn40ZUpWygq4w&usqp=CAU";

const PartnerItem = ({partner}) => {
  return (
    <div className="p-3" style={{width: '25%'}}>
      <img className="d-inline-block rounded-3" src={partner.logo.fluid.src} alt=""/>
    </div>
  )
}

const CooperativePartner = ({partners, title}) => {
  return (
    <section className="container-fluid p-4" style={{background: 'var(--main-color)'}}>
      <h2 className="text-center p-2" style={{color: "#ffffff"}}>{title ?? "Co-Branding"}</h2>
      <div className="container p-4 w-75">
        <div className={cls("p-4 d-flex flex-wrap")}>
          {
            partners.map((partner, index) => (<PartnerItem key={index} partner={partner} />))
          }
        </div>
      </div>
    </section>
  )
}

export default CooperativePartner;
