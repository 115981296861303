import React, {useState} from "react";
import * as styles from "./assurance.module.css";
import FullScreen from "../../shared/icons/fullscreen";
import cls from "classnames"
import {Modal} from "react-bootstrap";

function Assurance({info}) {
  const imageLink = info.imageLink.fluid.src;
  const title = info.imageLink.title;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className={`p-2 ${styles.imgWrapper}`} data-bs-toggle="modal" data-bs-target={"#assuranceModal" + info.id}>
        <img src={imageLink} alt={info.name} title={title} style={{height: '180px'}}/>
        <span className={styles.icon} onClick={handleShow}>
          <FullScreen />
        </span>
      </div>

      <Modal show={show} onHide={handleClose} dialogClassName="modal-custom">
        <Modal.Body>
          <div className="modal-dialog">
            <div className="modal-content" style={{zIndex: 99999999}}>
              <div className="position-absolute" style={{right: 0, padding: '1em'}}>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}/>
              </div>
              <img className={cls("w-100", styles.imgContext)} src={imageLink} alt={title} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}


const QualityAssurance = ({assurances, title}) => {
  return (
    <section className="container-fluid">
      <h2 className="text-center p-4">{ title ?? 'Quality Assurance'}</h2>
      <div className="d-flex justify-content-around">
        {
          assurances.map(x => (<Assurance key={x.id} info={x}/>))
        }
      </div>
    </section>
  )
}

export default QualityAssurance;
