import React from "react";
import { navigate } from "gatsby"
import cls from "classnames"
import * as styles from './prodcut.module.css'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import {useLang} from "../../hooks/useLang";

const ProductCard = ({name, link, title, identity}) => {
  const lang = useLang()
  return (
    <div className="col col-lg-3 p-2">
      <div className={styles.productLine}>
        <AniLink paintDrip to={`${lang}/product-line?tag=${identity}`} duration={0.3} hex="#9dc428">
          <div className={styles.imageWrapper}>
            <img className="d-inline-block" src={link} alt={title} title={title}/>
          </div>
          <div className={cls("text-center p-4", styles.name)}>
            { name }
          </div>
        </AniLink>
      </div>
    </div>
  )
}

const HomeProductLine = ({lines, title}) => {
  return (
    <section className="container">
      <h2 className="text-center p-4">{ title ?? 'Product Line'}</h2>
      <div className="row justify-content-md-center">
        {
          lines.map((x, index) => {
            return (
              <ProductCard key={index} name={x.name} link={x.imageLink.file.url} title={x.imageLink.title} identity={x.identity} />
            )
          })
        }
      </div>
    </section>
  )
}

export default HomeProductLine;
