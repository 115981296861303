import React, {memo, useState} from "react";
import cls from 'classnames'
import * as styles from './prodcut.module.css'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import {useLang} from "../../hooks/useLang";

const ProductMain = memo(({lines, title}) => {
  const lang = useLang()
  const [currentIndex, setCurrentIndex] = useState(0);

  lines.forEach(x => {
    x.products.forEach(y => {
      if (typeof window !== 'undefined') {
        const image = new Image(100, 200)
        image.src = y.imageLink[0].file.url
      }
    })
  })

  const renderItem = (product, index) => {
    return (
      <div key={index} className={cls(styles.cardWrapper, "col-md-6 col-lg-4 col-lg-3 p-2")}>
        <AniLink to={`${lang}/product/${product.link.trim().replace(' ', '-').toLowerCase()}`} hex="#9dc428"
                 paintDrip>
          <div className="row g-0">
            <div className={cls("col-5", styles.cardImg)}>
              <img src={product.imageLink[0].file.url} alt={product.imageLink[0].file.title}/>
            </div>
            <div className="col-7">
              <div className={styles.cardBody}>
                <h6 className="card-title">{product && product.productName}</h6>
                <p className={styles.price}>
                  {product.price} / Piece
                </p>
                <p className="text-muted">{product.minOrder} Piece(Min Order)</p>
              </div>
            </div>
          </div>
        </AniLink>
      </div>
    )
  }

  const renderNav = () => {
    return lines.map(x => x.name).map((name, index) => {
      return (
        <a key={index} className={styles.navItem}
           onClick={() => setCurrentIndex(index)}>
          <span style={{color: currentIndex === index ? 'var(--main-color)' : 'var(--font-main-color)'}}>
            {name}
          </span>
        </a>
      )
    })
  }

  return (
    <section className="container">
      <h2 className="text-center p-4">{title ?? 'Main Product'}</h2>
      <div className={styles.productMain}>
        <div className="p-2">
          <div className="container justify-content-center">
            <div className="d-flex flex-row justify-content-around p-3">
              {renderNav()}
            </div>
            <div className={cls(styles.navBody, 'p-4')}>
              {
                <div className="row align-items-start">
                  {
                    lines[currentIndex].products && lines[currentIndex].products.map((x, index) => renderItem(x, index))
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})

export default ProductMain;
