import React from "react";
import * as styles from "./form.module.css";
import cls from "classnames"

const MAP = {
  name: {
    en: 'Name',
    ar: 'الاسم'
  },
  mail: {
    en: 'Mail',
    ar: 'البريد'
  },
  phone: {
    en: 'Phone',
    ar: 'هاتف'
  },
  company: {
    en: 'Company',
    ar: 'الشركة'
  },
  content: {
    en: 'Content',
    ar: 'المحتوى'
  },
  send: {
    en: 'SEND MESSAGE',
    ar: 'أرسل رسالة'
  }
}

const Form = ({title, lang, fullwidth}) => {

  return (
    <div className="container p-4">
      <h2 className={cls("text-center p-2", fullwidth ? 'col-9' : '')}>{title ?? 'Contact Us'}</h2>
      <div className="row">
        {
          !fullwidth && <div className='col-3'/>
        }
        <form className={cls("align-self-center", fullwidth ? 'col-9' : 'col-6')} method="post" name="contact" data-netlify="true">
          <input type="hidden" name="form-name" value="contact" />
          <div className="row">
            <div className="mb-4 col-6">
              <label htmlFor="formName" className="form-label">{MAP.name[lang]}</label>
              <input type="text" className="form-control" id="formName" name="name" aria-describedby="emailHelp"/>
              {/*<div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>*/}
            </div>
            <div className="mb-4 col-6">
              <label htmlFor="formMail" className="form-label">{MAP.mail[lang]}</label>
              <input type="email" className="form-control" name="email" id="formMail"/>
            </div>
          </div>
          <div className="row">
            <div className="mb-4 col-6">
              <label htmlFor="formPhone" className="form-label">{MAP.phone[lang]}</label>
              <input type="text" className="form-control" id="formPhone" name="phone" aria-describedby="emailHelp"/>
              {/*<div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>*/}
            </div>
            <div className="mb-4 col-6">
              <label htmlFor="formCompany" className="form-label">{MAP.company[lang]}</label>
              <input type="text" className="form-control" id="formCompany" name="company"/>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="formContent" className="form-label">{MAP.content[lang]}</label>
            <textarea type="text" className="form-control" id="formContent" name="content"/>
          </div>
          <div className={cls("text-center p-1", styles.submitBtn)}>
            <button type="submit" className={cls("btn", styles.formBtn)}>{MAP.send[lang]}</button>
          </div>
        </form>
        <div className='col-3'/>
      </div>
    </div>
  )
}

export default Form;
